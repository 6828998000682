import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './auth.state'
import { signInOperation } from './operations/signIn.operation'
import { changePasswordOperation } from './operations/change-password.operation'
import { refreshTokenOperation } from './operations/refresh-tokens.operation'
import { verifyEmailOperation } from './operations/verify-email.operation'
import { signUpOperation } from './operations/sign-up.operation'
import { refreshUserOperation } from './operations/refresh-user.operation'
import { changeAccountSettings } from './operations/change-account-settings.operation'

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state) => {
      state.isLoggedIn = true
    },
    logout: (state) => {
      state.isLoggedIn = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signUpOperation.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(signInOperation.pending, (state) => {
        state.isLoading = true
      })
      .addCase(signInOperation.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(signInOperation.fulfilled, (state, action) => {
        state.isLoading = false
        state.isLoggedIn = true
        state.tokens = action.payload.tokens
        state.user = action.payload.user
      })
      .addCase(changePasswordOperation.fulfilled, (state, action) => {
        state.tokens = action.payload.tokens
      })
      .addCase(refreshTokenOperation.fulfilled, (state, action) => {
        state.tokens = action.payload
      })
      .addCase(refreshTokenOperation.rejected, (state) => {
        state.tokens.accessToken = null
        state.tokens.refreshToken = null
        state.isLoggedIn = false
      })
      .addCase(verifyEmailOperation.pending, (state) => {
        state.isLoading = true
      })
      .addCase(verifyEmailOperation.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(verifyEmailOperation.fulfilled, (state, action) => {
        state.isLoading = false
        state.tokens = action.payload.tokens
        state.user = action.payload.user
      })
      .addCase(refreshUserOperation.fulfilled, (state, action) => {
        state.user = action.payload
      })
      .addCase(refreshUserOperation.rejected, (state) => {
        state.isLoading = false
        state.isLoggedIn = false
      })
      .addCase(changeAccountSettings.fulfilled, (state, action) => {
        state.user.toothType = action.payload.toothType
      })
  },
})

export const authActions = authSlice.actions
export const authReducer = authSlice.reducer
