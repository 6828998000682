import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const deleteTreatmentPlan = createAsyncThunk<number, number>(
  'home/delete-treatment-plan',
  async (id, thunkAPI) => {
    try {
      await axios.delete(`/treatment-plan/${id}`)

      return id
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
