import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppointmentStage } from '../treatmentPlan.state'

interface Response {
  message: string
  stage: AppointmentStage
}

export const deleteAppointmentStageById = createAsyncThunk<Response, number>(
  'treatmentPlan/delete-appointment-stage-by-id',
  async (stageId, thunkAPI) => {
    try {
      const response = await axios.delete(`/appointment-stage/${stageId}`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
