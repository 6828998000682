import styled from '@emotion/styled'
import { Button } from 'components/Button'
// import { Button } from '@mui/material'
import { Colors } from 'constants/colors'

export const Btn = styled(Button)`
  min-width: 50px;
  height: 50px;

  border-radius: 50%;

  padding: 0;

  background-color: ${Colors.darkWhite};
  color: ${Colors.blueAccent};

  &:hover,
  &:focus {
    background-color: ${Colors.blueAccent};
    color: ${Colors.darkWhite};
  }
`
