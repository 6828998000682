import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { Equipment } from 'store/auth/auth.state'

export const createNewEquipment = createAsyncThunk<Equipment, number>(
  'settings/create-new-equipment',
  async (clinicId, thunkAPI) => {
    try {
      const response = await axios.post(`/clinic/${clinicId}/equipment`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
