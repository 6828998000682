import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { Patient } from '../home.state'

export const getPatients = createAsyncThunk<Patient[]>('home/get-patients', async (_, thunkAPI) => {
  try {
    const response = await axios.get('/patient')

    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})
