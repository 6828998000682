import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { IPortfolio } from '../settings.state'

export const deletePortfolioById = createAsyncThunk<IPortfolio, number>(
  'settings/delete-portfolio-by-id',
  async (portfolioId, thunkAPI) => {
    try {
      const response = await axios.delete(`/portfolio/${portfolioId}`)

      return response.data.portfolio
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
