import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { IPortfolioImage } from '../settings.state'
import Compressor from 'compressorjs'

interface Credentials {
  portfolioId: number

  image: File
  zoom: number
  crop: { x: number; y: number; width: number; height: number }
}

export const uploadPortfolioImage = createAsyncThunk<IPortfolioImage, Credentials>(
  'settings/upload-portfolio-image',
  async ({ portfolioId, image, zoom, crop }, thunkAPI) => {
    try {
      const compressedImage = await new Promise((resolve, reject) => {
        new Compressor(image, {
          quality: 0.8,
          success(result) {
            resolve(result)
          },
          error(err) {
            reject(err)
          },
        })
      })
      const formData = new FormData()
      formData.append('image', compressedImage as Blob)
      formData.append('zoom', zoom.toString())
      formData.append('crop', JSON.stringify(crop))

      // Отправка запроса
      const response = await axios.post(`/portfolio/${portfolioId}/image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
