import { NameTranslate } from 'store/treatmentPlan/treatmentPlan.state'

export interface Patient {
  id: number
  firstName: string
  lastName: string
  personalId: string
  createdAt: string
  doctorId: number
}

export interface TreatmentPlan {
  id: number
  name: string
  description: string
  nameTranslate: string
  createdAt: string
  updateAt: string
  patientId: number
  teeth: number[]
}

export interface PatientAppointments {
  id: number
  name: string
  treatmentPlanId: number
  treatmentPlanName: string
  treatmentPlanNameTranslate: NameTranslate
  totalDuration: string
  totalPrice: string
  currency: string
  teeth: number[]
}

export interface HomeState {
  patientId: null | number
  patients: Map<number, Patient>
  page: number
  perPage: number
  searchPatientsValue: string
  patientTreatmentPlans: TreatmentPlan[]
  patientAppointments: PatientAppointments[]
  patientsLoading: boolean
}

export const initialState: HomeState = {
  searchPatientsValue: '',
  page: 1,
  perPage: 24,
  patientId: null,
  patients: new Map(),
  patientTreatmentPlans: [],
  patientAppointments: [],
  patientsLoading: false,
}
