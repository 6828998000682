import ReactDOM from 'react-dom/client'
import App from './App'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { store, persistor } from 'store'
import { Icons } from 'components/Icons'
// import { I18nProvider } from '@lingui/react'
// import { i18n } from '@lingui/core'
// import { messages } from './locales/en/messages'
import { PersistGate } from 'redux-persist/integration/react'
import './helpers/interceptors/response.interceptors'
import { TranslatorProvider } from 'components/TranslatorProvider'

// i18n.load('en', messages)
// i18n.activate('en')

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <BrowserRouter>
    <TranslatorProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Icons />
          <App />
        </PersistGate>
      </Provider>
    </TranslatorProvider>
  </BrowserRouter>,
)
