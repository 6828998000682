export enum NotificationType {
  Auth = 'auth',
  HeaderVisible = 'header-visible',
  HeaderNotVisible = 'header-not-visible',
}

export const notificationPosition = {
  [NotificationType.Auth]: `
  position: absolute;
  top: 140px; 
  left: -40px`,
  [NotificationType.HeaderVisible]: `
  position: absolute;
  top: 110px; 
  right: 0;`,
  [NotificationType.HeaderNotVisible]: `
  position: fixed;
  top: 40px; 
  right: 40px;
  `,
}
