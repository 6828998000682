import { Colors } from 'constants/colors'

export enum ButtonColor {
  Blue = 'blue',
  LightBlue = 'lightblue',
  Red = 'red',
  Transparent = 'transparent',
}

export const buttonColors = {
  [ButtonColor.Blue]: (props: { isActive: boolean }) => `
  color: ${Colors.white};
  background-color: ${props.isActive ? Colors.activeBlueColor : Colors.blueAccent};

  :hover,
  :focus {
    background-color: ${Colors.activeBlueColor};
  }
  :active {

    box-shadow: 0px 4px 16px 0px #4758EB66;
  }
  `,
  [ButtonColor.LightBlue]: (props: { isActive: boolean }) => `
  color: ${props.isActive ? Colors.white : Colors.blueAccent};
  background-color: ${props.isActive ? Colors.blueAccent : Colors.backBorder};

  :hover, :focus {
    background-color: ${Colors.border};
  }

  :active {
    background-color: ${Colors.blueAccent};
    color: ${Colors.white};
    box-shadow: 0px 4px 16px 0px #4758EB66;
  }
  :disabled {
    color: ${Colors.blueAccent}
  }
  `,
  [ButtonColor.Red]: (props: { isActive: boolean }) => `
  color: ${props.isActive ? Colors.white : Colors.priorityRedSelected};
  background-color: ${props.isActive ? Colors.priorityRedSelected : Colors.priorityRed};

  &:hover, &:focus {
    color: ${Colors.priorityRedSelected};
    background-color: ${Colors.errorBgColor};
  }

  :active {
    background-color: ${Colors.priorityRedSelected};
    color: ${Colors.white};
    box-shadow: 0px 4px 16px 0px #E6191966;
  }
  :disabled {
    color: ${Colors.priorityRedSelected}
  }
  `,
  [ButtonColor.Transparent]: (props: { isActive: boolean }) => `
  color: ${props.isActive ? Colors.white : Colors.blueAccent};
  background-color: ${props.isActive ? Colors.blueAccent : 'transparent'};

  :hover, :focus {
    background-color: ${Colors.border};
  }

  :active {
    background-color: ${Colors.blueAccent};
    color: ${Colors.white};
    box-shadow: 0px 4px 16px 0px #4758EB66;
  }
  :disabled {
    color: ${Colors.blueAccent}
  }
  `,
}
