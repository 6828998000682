import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './settings.state'
import { createNewStage } from './operations/create-new-stage.operation'
import { getStages } from './operations/get-stages.operation'
import { updateStages } from './operations/update-stages.operation'
import { getClinicInfo } from './operations/get-clinic-info.operation'
import { createNewDoctor } from './operations/create-new-doctor.operation'
import { saveClinicInfo } from './operations/save-clinic-info.operation'
import { deleteDoctorById } from './operations/delete-doctor.operation'
import { saveDoctorInfo } from './operations/save-doctor-info.operation'
import { deleteCourseById } from './operations/delete-course-by-id.operation'
import { createNewEquipment } from './operations/create-new-equipment.operation'
import { updateEquipment } from './operations/update-equipment.opearation'
import { deleteEquipmentById } from './operations/delete-equipment.oparation'
import { uploadDoctorImage } from './operations/upload-doctor-image.operation'
import { uploadEquipmentImage } from './operations/upload-equipment-image.operation'
import { deleteDoctorImage } from './operations/delete-doctor-image.operation'
import { deleteEquipmentImage } from './operations/delete-equipment-image.operation'
import { updateMetadataDoctor } from './operations/update-metadata-doctor.operation'
import { updateMetadataEquipment } from './operations/update-metadata-equipment.operation'
import { deleteClinicLogo } from './operations/delete-clinic-logo.operation'
import { updateMetadataClinic } from './operations/update-metadata-clinic.operation'
import { uploadClinicLogo } from './operations/upload-clinic-logo.operation'
import { getAllPortfolio } from './operations/get-all-portfolio.operation'
import { createNewPortfolio } from './operations/create-new-portfolio.operation'
import { deletePortfolioById } from './operations/delete-portfolio-by-id.operation'
import { updatePortfolioInfo } from './operations/update-portfolio-info.operation'
import { uploadPortfolioImage } from './operations/upload-portfolio-image.operation'
import { deletePortfolioImage } from './operations/delete-portfolio-image.styled'
import { updateMetadataPortfolioImage } from './operations/update-metadata-portfolio-image.operation'
import { getStagesTotal } from './operations/get-stages-total.operation'

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createNewStage.fulfilled, (state, action) => {
        state.stages.push(action.payload)
        if (action.payload.subTreatmentId) {
          const subTreatment = state.stagesTotal.subTreatment[action.payload.subTreatmentId]

          subTreatment
            ? (state.stagesTotal.subTreatment[action.payload.subTreatmentId] +=
                action.payload.price)
            : (state.stagesTotal.subTreatment[action.payload.subTreatmentId] = action.payload.price)
        } else {
          const treatment = state.stagesTotal.treatment[action.payload.treatmentId]

          treatment
            ? (state.stagesTotal.treatment[action.payload.treatmentId] += action.payload.price)
            : (state.stagesTotal.treatment[action.payload.treatmentId] = action.payload.price)
        }
      })
      .addCase(getStages.fulfilled, (state, action) => {
        state.stages = action.payload
      })
      .addCase(updateStages.fulfilled, (state, action) => {
        state.stages = action.payload.updatedStages
        if (action.payload.updatedStages[0].subTreatmentId) {
          const subTreatment = action.payload.updatedStages[0].subTreatmentId
          const summ = action.payload.updatedStages.reduce((acc, stage) => {
            acc += stage.price
            return acc
          }, 0)
          state.stagesTotal.subTreatment[subTreatment] = summ
        } else {
          const treatment = action.payload.updatedStages[0].treatmentId
          const summ = action.payload.updatedStages.reduce((acc, stage) => {
            acc += stage.price
            return acc
          }, 0)
          state.stagesTotal.treatment[treatment] = summ
        }
      })
      .addCase(getClinicInfo.fulfilled, (state, action) => {
        state.hospital = action.payload
      })
      .addCase(createNewDoctor.fulfilled, (state, action) => {
        state.hospital.doctors[action.payload.id] = action.payload
      })
      .addCase(saveClinicInfo.fulfilled, (state, action) => {
        if (state.hospital.id === action.payload.id) {
          state.hospital.name = action.payload.name
          state.hospital.details = action.payload.details
        }
      })
      .addCase(deleteDoctorById.fulfilled, (state, action) => {
        delete state.hospital.doctors[action.payload.doctor.id]
      })
      .addCase(saveDoctorInfo.fulfilled, (state, action) => {
        state.hospital.doctors[action.payload.id] = action.payload
      })
      .addCase(deleteCourseById.fulfilled, (state, action) => {
        const doctor = state.hospital.doctors[action.payload.course.hospitalDoctorId]

        const filteredCourses = doctor.courses.filter((el) => el.id !== action.payload.course.id)

        doctor.courses = filteredCourses
      })
      .addCase(createNewEquipment.fulfilled, (state, action) => {
        state.hospital.equipments[action.payload.id] = action.payload
      })
      .addCase(updateEquipment.fulfilled, (state, action) => {
        state.hospital.equipments[action.payload.id] = action.payload
      })
      .addCase(deleteEquipmentById.fulfilled, (state, action) => {
        delete state.hospital.equipments[action.payload.equipment.id]
      })
      .addCase(uploadDoctorImage.fulfilled, (state, action) => {
        const doctor = state.hospital.doctors[action.payload.id]

        doctor.metadata = action.payload.metadata
        doctor.image = action.payload.image
      })
      .addCase(uploadEquipmentImage.fulfilled, (state, action) => {
        const equipment = state.hospital.equipments[action.payload.id]

        equipment.metadata = action.payload.metadata
        equipment.image = action.payload.image
      })
      .addCase(deleteDoctorImage.fulfilled, (state, action) => {
        const doctor = state.hospital.doctors[action.payload.id]

        doctor.image = action.payload.image
      })
      .addCase(deleteEquipmentImage.fulfilled, (state, action) => {
        const equipment = state.hospital.equipments[action.payload.id]

        equipment.image = action.payload.image
      })
      .addCase(updateMetadataDoctor.fulfilled, (state, action) => {
        state.hospital.doctors[action.payload.id] = action.payload
      })
      .addCase(updateMetadataEquipment.fulfilled, (state, action) => {
        state.hospital.equipments[action.payload.id] = action.payload
      })
      .addCase(deleteClinicLogo.fulfilled, (state, action) => {
        state.hospital.details = action.payload.details
      })
      .addCase(updateMetadataClinic.fulfilled, (state, action) => {
        state.hospital.details = action.payload.details
      })
      .addCase(uploadClinicLogo.fulfilled, (state, action) => {
        state.hospital.details = action.payload.details
      })
      .addCase(getAllPortfolio.fulfilled, (state, action) => {
        state.portfolio = action.payload
      })
      .addCase(createNewPortfolio.fulfilled, (state, action) => {
        state.portfolio[action.payload.id] = action.payload
      })
      .addCase(deletePortfolioById.fulfilled, (state, action) => {
        delete state.portfolio[action.payload.id]
      })
      .addCase(updatePortfolioInfo.fulfilled, (state, action) => {
        const portfolio = state.portfolio[action.payload.id]

        portfolio.name = action.payload.name
        portfolio.sortOrder = action.payload.sortOrder
      })
      .addCase(uploadPortfolioImage.fulfilled, (state, action) => {
        const portfolio = state.portfolio[action.payload.portfolioId]
        portfolio.portfolioImages.push(action.payload)
      })
      .addCase(deletePortfolioImage.fulfilled, (state, action) => {
        const portfolio = state.portfolio[action.payload.portfolioId]

        const updatePortfolioImages = portfolio.portfolioImages.filter(
          (image) => image.id !== action.payload.id,
        )

        portfolio.portfolioImages = updatePortfolioImages
      })
      .addCase(updateMetadataPortfolioImage.fulfilled, (state, action) => {
        const portfolio = state.portfolio[action.payload.portfolioId]
        if (!portfolio) return
        const images = portfolio.portfolioImages

        const imageIndex = images.findIndex((image) => image.id === action.payload.id)
        images[imageIndex] = action.payload
      })
      .addCase(getStagesTotal.fulfilled, (state, action) => {
        state.stagesTotal = action.payload
      })
  },
})

export const settingsActions = settingsSlice.actions
export const settingsReducer = settingsSlice.reducer
