import styled from '@emotion/styled'
import { Box, FormControl, Select } from '@mui/material'
import { Colors } from 'constants/colors'

export const StyledFormControl = styled(FormControl)`
  fieldset {
    border-color: ${Colors.blueLight};
    border-radius: 12px;
  }

  &:hover fieldset {
    border-color: ${Colors.blueAccent};
  }

  &.Mui-focused fieldset {
    border-color: ${Colors.blueAccent};
  }

  &.Mui-shrink fieldset {
    border-color: ${Colors.blueLight};
  }

  &.Mui-error fieldset {
    border-color: ${Colors.errorColor};
  }
  .MuiInputBase-root {
    border-color: ${Colors.blueAccent};
  }
  &:hover .MuiInputBase-root {
    border: 'none';
    border-color: ${Colors.blueAccent};
  }

  .MuiInputBase-input {
    color: ${Colors.textColor};
    padding: 8px;

    font-family: Rubik;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
  }
  &:hover .MuiInputBase-input {
    border: 'none';
    border-color: ${Colors.blueAccent};
  }
`

export const StyledSelect = styled(Select)`
  .MuiInputBase-root:hover {
    border-color: ${Colors.blueAccent};
  }
`

export const LangBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`
//  <div class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl css-uj5wlb-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root">
