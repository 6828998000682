import styled from '@emotion/styled'
import { notificationPosition } from './NotificationBar.types'

interface ListProps {
  type: 'auth' | 'header-visible' | 'header-not-visible'
}

export const List = styled.ul<ListProps>`
  width: 480px;
  z-index: 1000;

  ${({ type }) => notificationPosition[type]}
  display: flex;
  flex-direction: column;
  gap: 12px;
`
export const Item = styled.li`
  :not(:first-of-type) {
    margin-top: 5px;
  }
`
