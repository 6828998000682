import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { IPortfolio } from '../settings.state'

export const createNewPortfolio = createAsyncThunk<IPortfolio>(
  'settings/create-new-portfolio',
  async (_, thunkAPI) => {
    try {
      const response = await axios.post('/portfolio')

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
