import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { ToothType, User } from '../auth.state'

export const changeAccountSettings = createAsyncThunk<User, ToothType>(
  'auth/change-account-settings',
  async (toothType, thunkAPI) => {
    try {
      const response = await axios.patch('/user', { toothType })

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
