import { Logo } from 'modules/home/components/Logo'
import { LinkText, List, LogoutBtn, MenuBox, StyledLink } from './Menu.styled'
import { Icon } from 'components/Icon'
import React from 'react'
import { useAppDispatch } from 'store/hooks'
import { authActions } from 'store/auth'
import { Trans } from '@lingui/macro'
import useConditionalNavigate from 'hooks/useConditialNavigate'

interface MenuProps {
  toggleMenu: () => void
}

const Menu: React.FC<MenuProps> = ({ toggleMenu }) => {
  const dispatch = useAppDispatch()
  const navigate = useConditionalNavigate()
  const handleLogOut = () => {
    toggleMenu()
    dispatch(authActions.logout())
  }

  const handleClick = (to: string) => {
    navigate(to)
    toggleMenu()
  }

  return (
    <MenuBox>
      <Logo />
      <List>
        <li>
          <StyledLink onClick={() => handleClick('/')}>
            <Icon name='patients' size={24} />
            <LinkText>
              <Trans id='xzUJyZ5tGHBFSWP5fjysVf-home'>Patients</Trans>
            </LinkText>
          </StyledLink>
        </li>
        {/* <li>
          <StyledLink to='/' onClick={toggleMenu}>
            <Icon name='calendar' size={24} />
            <LinkText>
              <Trans id='oHP4EneLeVa1ALNqrHUVtt-home'>Appoinments</Trans>
            </LinkText>
          </StyledLink>
        </li> */}
        <li>
          <StyledLink onClick={() => handleClick('/settings')}>
            <Icon name='settings' size={24} />
            <LinkText>
              <Trans id='3PkTJzmoZF4C4viog3VQG1-home'>Settings</Trans>
            </LinkText>
          </StyledLink>
        </li>
      </List>
      <LogoutBtn onClick={handleLogOut}>
        <Icon name='log-out' size={24} />
        <LinkText>
          <Trans id='1wY2kQQHgaTnvUJGodYLQK-home'>Log Out</Trans>
        </LinkText>
      </LogoutBtn>
    </MenuBox>
  )
}

export default Menu
