import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { Patient } from '../home.state'

export const getSearchPatients = createAsyncThunk<Patient[], string>(
  'home/get-search-patients',
  async (search, thunkAPI) => {
    try {
      const response = await axios.get(`/patient?search=${search}`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
