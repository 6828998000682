import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { Tokens, User } from '../auth.state'

interface VerifyResponse {
  tokens: Tokens
  user: User
}

export const verifyEmailOperation = createAsyncThunk<VerifyResponse, string>(
  'auth/verify-email',
  async (token, thunkAPI) => {
    try {
      const response = await axios.get(`/auth/email/verify?emailToken=${token}`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)
