import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { Button } from 'components/Button'
import { Colors } from 'constants/colors'

export const ProfileBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
`

export const ProfileCard = styled(Box)`
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  padding: 0 16px 0 8px;

  background-color: ${Colors.darkWhite};
  color: ${Colors.textColorInfo};

  border-radius: 24px;

  font-family: Rubik;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);

  :hover,
  :focus {
    background-color: ${Colors.border};
  }
`

export const ProfileImg = styled.img`
  width: 34px;
  height: 34px;
  object-fit: cover;
  border-radius: 50%;
`

export const MenuBtn = styled(Button)`
  height: 48px;
  gap: 8px;

  padding: 0 16px;

  border-radius: 24px;
  color: ${Colors.textColorInfo};
  background-color: ${Colors.darkWhite};
`
