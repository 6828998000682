import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { ICourse } from 'modules/settings/components/DoctorCard/DoctorCard'
import { Doctor, IFeedback } from 'store/auth/auth.state'

interface ReqCourse {
  id?: number
  name: string
  year: string
  sortOrder: number
}

interface Credentials {
  clinicId: number
  doctorId: number
  values: {
    name: string
    specialization: string
    description: string
    courses: ICourse[]
    feedback: IFeedback[]
  }
}

interface IObject {
  name: string
  specialization: string
  description: string
  courses: ReqCourse[]
  feedback: IFeedback[]
}

export const saveDoctorInfo = createAsyncThunk<Doctor, Credentials>(
  'settings/save-doctor-info',
  async ({ clinicId, doctorId, values }, thunkAPI) => {
    try {
      const reqObject: IObject = {
        name: values.name,
        description: values.description,
        specialization: values.specialization,
        courses: [],
        feedback: values.feedback,
      }

      values.courses.forEach((course) => {
        const reqCourse: ReqCourse = {
          name: course.name,
          year: course.year,
          sortOrder: course.sortOrder,
        }

        if (typeof course.id === 'number') {
          reqCourse.id = course.id
        }

        reqObject.courses.push(reqCourse)
      })

      const response = await axios.put(`/clinic/${clinicId}/doctor/${doctorId}`, reqObject)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
