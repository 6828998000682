import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { IPortfolio } from '../settings.state'

interface Credentials {
  portfolioId: number
  name: string
  sortOrder?: number
}

export const updatePortfolioInfo = createAsyncThunk<IPortfolio, Credentials>(
  'settings/update-portfolio-info',
  async ({ portfolioId, name, sortOrder }, thunkAPI) => {
    try {
      const response = await axios.put(`/portfolio/${portfolioId}`, { name, sortOrder })

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
