import { useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { treatmentPlanAction } from 'store/treatmentPlan/treatmentPlan.slice'
import { useAppDispatch } from 'store/hooks'

function useConditionalNavigate() {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch() // Или useAppDispatch, если вы используете кастомный хук

  const conditionalNavigate = useCallback(
    (to: string) => {
      const searchParams = new URLSearchParams(location.search)
      const needSave = searchParams.get('needAppointmentSave') === 'true'

      if (needSave) {
        dispatch(treatmentPlanAction.addPath(to))
        searchParams.set('savePopup', 'true')
        navigate(
          {
            pathname: location.pathname,
            search: `?${searchParams.toString()}`,
          },
          { replace: true },
        )
      } else {
        window.scrollTo(0, 0)
        navigate(to)
      }
    },
    [navigate, location, dispatch],
  )

  return conditionalNavigate
}

export default useConditionalNavigate
