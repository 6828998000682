import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { Appointments } from '../treatmentPlan.state'

export const deleteAllAppointments = createAsyncThunk<Appointments, string>(
  'treatmentPlan/delete-all-appointments',
  async (treatmentPlanId, thunkAPI) => {
    try {
      const response = await axios.delete(`/treatment-plan/${treatmentPlanId}/appointments`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
