import { ToothType } from 'store/auth/auth.state'

export const toothNumbers = {
  [ToothType.USA]: {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    10: 10,
    11: 11,
    12: 12,
    13: 13,
    14: 14,
    15: 15,
    16: 16,
    17: 32,
    18: 31,
    19: 30,
    20: 29,
    21: 28,
    22: 27,
    23: 26,
    24: 25,
    25: 24,
    26: 23,
    27: 22,
    28: 21,
    29: 20,
    30: 19,
    31: 18,
    32: 17,
  },
  [ToothType.EURO]: {
    1: 18,
    2: 17,
    3: 16,
    4: 15,
    5: 14,
    6: 13,
    7: 12,
    8: 11,
    9: 21,
    10: 22,
    11: 23,
    12: 24,
    13: 25,
    14: 26,
    15: 27,
    16: 28,
    17: 48,
    18: 47,
    19: 46,
    20: 45,
    21: 44,
    22: 43,
    23: 42,
    24: 41,
    25: 31,
    26: 32,
    27: 33,
    28: 34,
    29: 35,
    30: 36,
    31: 37,
    32: 38,
  },
}
