import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { TreatmentPlan } from '../home.state'

interface Response {
  treatmentPlans: TreatmentPlan[]
}

export const getTreatmentPlans = createAsyncThunk<Response, number>(
  'home/get-treatment-plans',
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`/treatment-plan?patientId=${id}`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
