import React, { ReactNode, useEffect, useState } from 'react'
import { StyledCloseBtn, ModalWindow, Overlay } from './Popup.styled'
import { useSpring } from 'react-spring'

interface PopupProps {
  toggleModal: () => void
  children?: ReactNode
}

const Popup: React.FC<PopupProps> = ({ toggleModal, children }) => {
  const [isModal, setIsModal] = useState(false)

  const closeModal = () => {
    setIsModal(false)
  }

  const closeModalOnOverlay = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeModal()
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => setIsModal(true), 100)
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    if (!isModal) {
      // Задержка, чтобы позволить анимации завершиться перед закрытием
      const timeoutId = setTimeout(() => {
        toggleModal()
      }, 300)

      return () => clearTimeout(timeoutId)
    }
  }, [isModal, toggleModal])

  useEffect(() => {
    const closeModalOnEscPush = (e: KeyboardEvent) => {
      const KEY_CODE_ESCAPE = 'Escape'

      if (e.code === KEY_CODE_ESCAPE) {
        closeModal()
      }
    }

    window.addEventListener('keydown', closeModalOnEscPush)

    return () => window.removeEventListener('keydown', closeModalOnEscPush)
  }, [toggleModal])

  const overlayAnimation = useSpring({
    backgroundColor: isModal ? '#24274166' : 'transparent',
    config: { duration: 300 },
  })

  return (
    <Overlay style={overlayAnimation} onClick={closeModalOnOverlay}>
      <ModalWindow isModal={isModal}>
        <StyledCloseBtn onClick={closeModal} />
        {children}
      </ModalWindow>
    </Overlay>
  )
}

export default Popup
