import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { Doctor } from 'store/auth/auth.state'

interface Credentials {
  clinicId: number
  doctorId: number

  zoom: number
  crop: { x: number; y: number; width: number; height: number }
}

export const updateMetadataDoctor = createAsyncThunk<Doctor, Credentials>(
  'settings/update-metadata-doctor',
  async ({ clinicId, doctorId, zoom, crop }, thunkAPI) => {
    try {
      const response = await axios.put(`/clinic/${clinicId}/doctor/${doctorId}`, {
        zoom,
        crop,
      })

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
