import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { ThunkApiConfig } from 'store/auth/operations/signIn.operation'

export interface PatientDto {
  firstName: string
  lastName: string
  personalId: string
}

export interface PatientRes {
  id: number
  firstName: string
  lastName: string
  personalId: string
  createdAt: string
  doctorId: number
  response?: {
    status: number
  }
}

export const newPatient = createAsyncThunk<PatientRes, PatientDto, ThunkApiConfig>(
  'home/new-patient',
  async (patient, thunkAPI) => {
    try {
      const response = await axios.post('/patient', patient)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
