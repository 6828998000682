import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { IAppointment } from '../treatmentPlan.state'

interface Response {
  message: string
  appointment: IAppointment
}

export const deleteAppointment = createAsyncThunk<Response, number>(
  'treatmentPlan/delete-appointment',
  async (appointentId, thunkAPI) => {
    try {
      const response = await axios.delete(`appointment/${appointentId}`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
