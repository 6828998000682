import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { Button } from 'components/Button'

import { Colors } from 'constants/colors'

export const MenuBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`
export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
export const StyledLink = styled(Box)`
  display: flex;
  gap: 10px;

  width: 100%;
  padding: 12px 24px;
  border-radius: 12px;

  color: ${Colors.blueAccent};
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);

  :hover,
  :focus {
    background-color: ${Colors.backBorder};
  }

  cursor: pointer;
`

export const LinkText = styled.span`
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;

  color: ${Colors.textColor};
`
export const LogoutBtn = styled(Button)`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  padding: 12px 24px;
  border-radius: 12px;

  color: ${Colors.blueAccent};
  background-color: transparent;

  text-transform: none;

  :hover,
  :focus {
    background-color: ${Colors.backBorder};
  }
`
