export const Colors = Object.freeze({
  white: '#fff',
  black: '#242741',
  darkWhite: '#F5F5FC',
  blueLight: '#D6D9FB',
  blueAccent: '#3043E9',
  textColor: '#242741',
  textColorInfo: '#2E2F37',
  errorColor: '#E93030',
  lightErrorColor: '#FCE7E7',
  successColor: '#149F54',
  successBgColor: '#DEFFED',
  infoBgColor: '#BAC0F8',
  errorBgColor: '#FAD1D1',
  attentionBgColor: '#FFF9DD',
  attentionColor: '#887616',
  border: '#D1D5FA',
  backBorder: '#E8EAFD',
  secondaryTextColor: '#5E606E',
  priorityRed: '#FCE8E8',
  priorityRedSelected: '#E61919',
  priorityYellow: '#FCEFCF',
  priorityYellowSelected: '#F3BC30',
  priorityGreen: '#D2F9E4',
  priorityGreenSelected: '#149F54',
  scrollColor: '#5E6CEE',
  priorityBtnRed: '#F7B2B2',
  priorityBtnYellow: '#F9DD97',
  priorityBtnYellowBorder: '#FDF2D6',
  priorityBtnYellowText: '#A27D20',
  priorityBtnGreenBorder: '#D0ECDD',
  priorityBtnGreen: '#89CFA9',
  chosenBtn: '#8C96F3',
  activeBlueColor: '#1629CF',
  borderGreenColor: '#B1DFC6',
  successGreenColor: '#EBFFF4',
  titleColor: '#2e2f37',
  footerColor: '#141734',
})
