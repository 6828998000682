import { createAsyncThunk } from '@reduxjs/toolkit'
import { Tokens } from '../auth.state'
import axios from 'axios'
import { setAuthToken } from './add-header-authorization'
import { ThunkApiConfig } from './signIn.operation'

interface Credentials {
  password: string
  accessToken: string
}

interface Response {
  tokens: Tokens
  message: string
  response?: {
    status: number
  }
}

export const changePasswordOperation = createAsyncThunk<Response, Credentials, ThunkApiConfig>(
  'auth/change-password',
  async (credentials, thunkAPI) => {
    try {
      setAuthToken(credentials.accessToken)

      const response = await axios.patch('/auth/change-password', {
        password: credentials.password,
      })

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
