import styled from '@emotion/styled'
import { CloseBtn } from 'components/CloseBtn'
import { animated } from 'react-spring'

export const Overlay = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #24274166;
  z-index: 200;
`

type Props = {
  isModal: boolean
}

export const ModalWindow = styled.div<Props>`
  position: relative;
  background-color: white;
  height: 100%;
  width: 477px;
  border-radius: 40px 0px 0px 40px;
  padding: 40px;
  z-index: 100;
  transform: ${({ isModal }) => (isModal ? 'translateX(0)' : 'translateX(100%)')};

  transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
`
export const StyledCloseBtn = styled(CloseBtn)`
  position: absolute;
  right: 20px;
  top: 35px;
`
