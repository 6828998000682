import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { IPortfolioImage } from '../settings.state'

interface Credentials {
  portfolioId: number
  imageId: number
}

export const deletePortfolioImage = createAsyncThunk<IPortfolioImage, Credentials>(
  'settings/delete-portfolio-image',
  async ({ portfolioId, imageId }, thunkAPI) => {
    try {
      const response = await axios.delete(`/portfolio/${portfolioId}/image/${imageId}`)

      return response.data.image
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
