import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { Patient } from 'store/home/home.state'

interface Request {
  link: string
  password: string
}

export const PatientSignIn = createAsyncThunk<Patient, Request>(
  'patient/sign-in',
  async ({ link, password }, thunkAPI) => {
    try {
      const response = await axios.post(`/presentation/sign-in/${link}`, { password })

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
