import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { CurrencyType, Stage } from '../settings.state'

interface newStage {
  name: string
  price: number
  currency: CurrencyType
  duration: number
  sortOrder: number
  treatmentId: number
  subTreatmentId: number | null
}

export const createNewStage = createAsyncThunk<Stage, newStage>(
  'settings/create-new-stage',
  async (credentials, thunkAPI) => {
    try {
      const response = await axios.post('/stages', credentials)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
