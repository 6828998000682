import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { TreatmentAppointments } from '../treatmentPlan.state'

export const getTreatmentAppointmentsById = createAsyncThunk<TreatmentAppointments, string>(
  'treatmentPlan/get-treatment-appointments-id',
  async (treatmentPlanId, thunkAPI) => {
    try {
      const response = await axios.get(`appointment/${treatmentPlanId}`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
