import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { initialState } from './patient.state'
import { getTreatmentLogin } from './operation/get-treatment-login.operation'
import { PatientSignIn } from './operation/patient-sign-in.operation'
import { getPresentationTreatmentPlan } from './operation/get-own-treatment-plan.operation'
import { getClinicInfo } from './operation/get-clinic-info.operation'
import { getCases } from './operation/get-cases.operation'
import { getPatientAppointments } from './operation/get-appointments.operation'
import { getPresentationTreatmentPlanByDoctor } from './operation/get-treatment-plan-by-doctor.operation'
import { getClinicInfoByDoctor } from './operation/get-clinic-info-by-doctor.operation'
import { getPatientAppointmentsByDoctor } from './operation/get-appointments-by-doctor.operation'
import { getCasesByDoctor } from './operation/get-cases-by-doctor.operation'
import { getToothVideosByDoctor } from './operation/get-videos-by-tooth.operation'
import { getToothVideosByPatient } from './operation/get-videos-by-patient.operation'

const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    setTooth: (state, action: PayloadAction<number>) => {
      const toothInfo = state.treatmentPlan?.teeth[action.payload]
      const images = state.treatmentPlan?.treatmentImages

      if (!images) {
        if (toothInfo?.patientDiagnoses?.length) {
          state.selectedTooth = action.payload
        }
      } else {
        const array = Object.values(images).reduce((acc, el) => {
          const teeth = el.metadata.teeth

          teeth.map((el) => acc.push(el.toothId))
          return acc
        }, [])

        if (toothInfo?.patientDiagnoses?.length || array.includes(action.payload)) {
          state.selectedTooth = action.payload
        }
      }
    },
    nextIndex: (state) => {
      const index = state.selectedImageIndex
      const length = state.treatmentPlan.treatmentImages.length

      state.selectedImageIndex = length === index + 1 ? 0 : index + 1
    },
    prevIndex: (state) => {
      const index = state.selectedImageIndex
      const length = state.treatmentPlan.treatmentImages.length

      state.selectedImageIndex = index === 0 ? length - 1 : index - 1
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTreatmentLogin.fulfilled, (state, action) => {
        state.loginInfo = action.payload
      })
      .addCase(PatientSignIn.fulfilled, (state, action) => {
        state.patient = action.payload
        state.isLoggedIn = true
      })
      .addCase(getPresentationTreatmentPlan.fulfilled, (state, action) => {
        state.treatmentPlan = action.payload.treatmentPlan
      })
      .addCase(getPresentationTreatmentPlanByDoctor.fulfilled, (state, action) => {
        state.treatmentPlan = action.payload.treatmentPlan
      })
      .addCase(getClinicInfo.fulfilled, (state, action) => {
        state.clinic = action.payload.clinic
        state.toothType = action.payload.toothType
      })
      .addCase(getClinicInfoByDoctor.fulfilled, (state, action) => {
        state.clinic = action.payload.clinic
        state.toothType = action.payload.toothType
      })
      .addCase(getCases.fulfilled, (state, action) => {
        state.cases = action.payload
      })
      .addCase(getCasesByDoctor.fulfilled, (state, action) => {
        state.cases = action.payload
      })
      .addCase(getPatientAppointments.fulfilled, (state, action) => {
        state.appointments = action.payload
      })
      .addCase(getPatientAppointmentsByDoctor.fulfilled, (state, action) => {
        state.appointments = action.payload
      })
      .addCase(getToothVideosByDoctor.fulfilled, (state, action) => {
        state.videos = action.payload
      })
      .addCase(getToothVideosByPatient.fulfilled, (state, action) => {
        state.videos = action.payload
      })
  },
})

export const patientAction = patientSlice.actions
export const patientReducer = patientSlice.reducer
