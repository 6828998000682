import styled from '@emotion/styled'
// import { Button } from '@mui/material'
import { buttonColors } from './Button.types'
import { fontStyle } from 'constants/fontStyle'

type Variants = 'lightblue' | 'blue' | 'red' | 'transparent'

type Props = {
  isActive: boolean
  styledColor: Variants
}

export const StyledButton = styled.button<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  border-radius: 12px;
  gap: 8px;
  flex-shrink: 0;

  ${fontStyle.bodyTextmd400};

  text-transform: none;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);

  :disabled {
    opacity: 0.5;
  }

  ${({ styledColor, isActive }) => {
    const styleFunction = buttonColors[styledColor]
    return styleFunction ? styleFunction({ isActive }) : ''
  }}
`
