import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { Doctor } from 'store/auth/auth.state'

interface ICredentials {
  clinicId: number
  doctorId: number
}

export const deleteDoctorImage = createAsyncThunk<Doctor, ICredentials>(
  'settings/delete-doctor-image',
  async ({ clinicId, doctorId }, thunkAPI) => {
    try {
      const response = await axios.delete(`/clinic/${clinicId}/doctor/${doctorId}/image`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
