import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { Doctor } from 'store/auth/auth.state'

interface IResponse {
  message: string
  doctor: Doctor
}
interface ICredentials {
  clinicId: number
  doctorId: number
}

export const deleteDoctorById = createAsyncThunk<IResponse, ICredentials>(
  'settings/delete-doctor-by-id',
  async ({ clinicId, doctorId }, thunkAPI) => {
    try {
      const response = await axios.delete(`/clinic/${clinicId}/doctor/${doctorId}`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
