import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { PatientDiagnose } from '../treatmentPlan.state'

interface Request {
  treatmentPlanId: number
  ids: number
}

export const deleteToothDiagnose = createAsyncThunk<PatientDiagnose[], Request>(
  'treatmentPlan/delete-tooth-diagnose',
  async (credentials, thunkAPI) => {
    try {
      const ids = `?ids=${credentials.ids}`

      const response = await axios.delete(`/patient-diagnosis/${credentials.treatmentPlanId}${ids}`)

      return response.data.diagnosis
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
