import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { TreatmentVariant } from '../treatmentPlan.state'
import { ThunkApiConfig } from 'store/auth/operations/signIn.operation'

interface IResponse {
  newMainVariant: TreatmentVariant
  oldMainVariant: TreatmentVariant
  response?: {
    status: number
  }
}

interface Request {
  id: number
  allowDeletion?: boolean
}

export const changeMainVariant = createAsyncThunk<IResponse, Request, ThunkApiConfig>(
  'treatmentPlan/change-main-variant',
  async (credentails, thunkAPI) => {
    try {
      const allowDeletion = credentails.allowDeletion === true ? '?allowDeletion=true' : ''

      const response = await axios.patch(`/treatment-variant/${credentails.id}${allowDeletion}`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
