import { lazy, LazyExoticComponent, ComponentType } from 'react'

// import { EmotionJSX } from '@emotion/react/types/jsx-namespace'
// import { Appointment } from 'modules/pages/appointment'
// import { ChangePassword } from 'modules/pages/changePassword'
// import { ForgotPassword } from 'modules/pages/forgotPassword'
// import { Home } from 'modules/pages/home'
// import { Login } from 'modules/pages/login'
// import { PatientLogin } from 'modules/pages/patientLogin'
// import { Review } from 'modules/pages/review'
// import { Settings } from 'modules/pages/settings'
// import { SignUp } from 'modules/pages/signUp'
// import { Treatment } from 'modules/pages/treatment'
// import { Presentation } from 'modules/pages/presentation'
// import { TreatmentPlanPresentation } from 'modules/pages/treatmentPlanPresentation'
// import { VerifyEmail } from 'modules/pages/verifyEmail'
// import { PresentationError } from 'modules/pages/presentationError'
// import { NotFoundPage } from 'modules/pages/notFound'

const Appointment = lazy(() => import('../modules/pages/appointment'))
const ChangePassword = lazy(() => import('../modules/pages/changePassword'))
const ForgotPassword = lazy(() => import('../modules/pages/forgotPassword'))
const Home = lazy(() => import('../modules/pages/home'))
const Login = lazy(() => import('../modules/pages/login'))
const PatientLogin = lazy(() => import('../modules/pages/patientLogin'))
const Review = lazy(() => import('../modules/pages/review'))
const Settings = lazy(() => import('../modules/pages/settings'))
const SignUp = lazy(() => import('../modules/pages/signUp'))
const Treatment = lazy(() => import('../modules/pages/treatment'))
const Presentation = lazy(() => import('../modules/pages/presentation'))
const TreatmentPlanPresentation = lazy(() => import('../modules/pages/treatmentPlanPresentation'))
const VerifyEmail = lazy(() => import('../modules/pages/verifyEmail'))
const PresentationError = lazy(() => import('../modules/pages/presentationError'))
const NotFoundPage = lazy(() => import('../modules/pages/notFound'))

export interface IRoute {
  path: string
  component: LazyExoticComponent<ComponentType>
  private?: boolean
  restricted?: boolean
  redirect: string
  patient?: boolean
}

export const routes: IRoute[] = [
  { path: '/sign-in', component: Login, restricted: true, redirect: '/' },
  { path: '/sign-up', component: SignUp, restricted: true, redirect: '/' },
  { path: '/verify', component: VerifyEmail, restricted: true, redirect: '/' },
  { path: '/forgot-password', component: ForgotPassword, restricted: true, redirect: '/' },
  { path: '/change-password', component: ChangePassword, restricted: true, redirect: '/' },
  { path: '/', component: Home, private: true, redirect: '/sign-in' },
  { path: '/settings', component: Settings, private: true, redirect: '/sign-in' },
  { path: '/treatment/:id', component: Treatment, private: true, redirect: '/sign-in' },
  {
    path: '/treatment/:id/appointment',
    component: Appointment,
    private: true,
    redirect: '/sign-in',
  },
  {
    path: '/treatment/:id/presentation',
    component: Presentation,
    private: true,
    redirect: '/sign-in',
  },
  {
    path: '/treatment/:id/review',
    component: Review,
    private: true,
    redirect: '/sign-in',
  },
  {
    path: '/patient-login/:id',
    component: PatientLogin,
    patient: true,
    restricted: true,
    redirect: '/patient-treatment-plan',
  },
  {
    path: '/error/:id',
    component: PresentationError,
    patient: true,
    restricted: true,
    redirect: '/patient-login',
  },
  {
    path: '/patient-treatment-plan/:id',
    component: TreatmentPlanPresentation,
    patient: true,
    private: true,
    redirect: '/patient-login',
  },
  { path: '*', component: NotFoundPage, redirect: '/' },
]
