import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { IPortfolio } from 'store/settings/settings.state'
import { IPatientTreatmentPlan, PatientAppointment } from '../patient.types'
import { RootState } from 'store'

interface Response {
  appointments: PatientAppointment[]
  cases: IPortfolio[]
  treatmentPlan: IPatientTreatmentPlan
}

export const getPresentationTreatmentPlan = createAsyncThunk<Response, string>(
  'patient/get-presentation-tretmentPlan',
  async (link, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState

      const config = {
        headers: {
          Authorization: state.patient.patient.personalId,
        },
      }

      const response = await axios.get(`/presentation/treatment-plan/${link}`, config)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
