import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { Priority } from '../treatmentPlan.state'

interface Request {
  treatmentPlanId: number
  ids: number
}

export const deleteToothPriority = createAsyncThunk<Priority[], Request>(
  'treatmentPlan/delete-tooth-priority',
  async (credentials, thunkAPI) => {
    try {
      const ids = `?ids=${credentials.ids}`

      const response = await axios.delete(
        `/treatment-plan-priority/${credentials.treatmentPlanId}${ids}`,
      )

      return response.data.priority
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
