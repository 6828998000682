import { PatientState } from './patient.types'

export const initialState: PatientState = {
  isLoggedIn: false,
  loginInfo: null,
  personalId: null,
  patient: null,
  clinic: null,
  appointments: null,
  cases: null,
  treatmentPlan: null,
  selectedImageIndex: 0,
  selectedTooth: null,
  toothType: null,
  videos: null,
}
