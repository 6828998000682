import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { Hospital } from 'store/auth/auth.state'

interface Credentials {
  id: number
  values: {
    name: string
    address: string
    phone: string
    email: string
    description: string
    website: string
    facebook: string
    instagram: string
    linkedIn: string
  }
}

interface IObject {
  name: string
  description: string
  contacts: {
    location: string
    phone: string
    email?: string
  }
  socialMedia: {
    website: string
    facebook: string
    instagram: string
    linkedIn: string
  }
}

export const saveClinicInfo = createAsyncThunk<Hospital, Credentials>(
  'settings/save-clinic-info',
  async ({ id, values }, thunkAPI) => {
    try {
      const reqObject: IObject = {
        name: values.name,
        description: values.description,
        contacts: {
          location: values.address,
          phone: values.phone,
        },
        socialMedia: {
          facebook: values.facebook,
          instagram: values.instagram,
          linkedIn: values.linkedIn,
          website: values.website,
        },
      }
      if (values.email !== '') {
        reqObject.contacts.email = values.email
      }

      const response = await axios.put(`/clinic/${id}`, reqObject)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
