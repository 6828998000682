import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState } from './notification.state'

const notificatonSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    add: (
      state,
      action: PayloadAction<{
        id: string
        label: string
        type: 'success' | 'info' | 'attention' | 'alert'
      }>,
    ) => {
      state.notifications.push({
        id: action.payload.id,
        label: action.payload.label,
        type: action.payload.type,
      })
    },
    remove: (state, action: PayloadAction<{ id: string }>) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== action.payload.id,
      )
    },
  },
})

export const notificationActions = notificatonSlice.actions
export const notificationReducer = notificatonSlice.reducer
