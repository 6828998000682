import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'

export const useCustomRouter = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const push = (pathname, asPath, { locale }) => {
    const isMainPage = location.pathname === '/'

    // Формируем новый путь, добавляя локаль, если она указана
    const newPath = locale && !isMainPage ? `/${locale}${pathname}` : pathname

    // Заменяем текущий путь новым путем
    navigate(newPath + asPath, { replace: true })
  }

  return {
    locale: location.pathname.split('/')[1], // или другой метод для определения локали
    query: queryString.parse(location.search),
    push,
    pathname: location.pathname,
    asPath: location.pathname + location.search,
  }
}
