import { DiagnoseCategory, TreatmentCategory } from './treatment.types'

export interface TreatmentState {
  treatments: TreatmentCategory[]
  diagnosis: DiagnoseCategory[]
}

export const initialState: TreatmentState = {
  treatments: [],
  diagnosis: [],
}
