import { Doctor, Equipment, HospitalDetails } from 'store/auth/auth.state'

export enum CurrencyType {
  USD = 'usd',
  EURO = 'euro',
  CZK = 'czk',
  UAH = 'uah',
}

export interface Stage {
  id: number
  name: string
  price: number
  currency: CurrencyType
  duration: number
  sortOrder: number
  treatmentId: number
  subTreatmentId: number
  doctorId: number
  createdAt: string
  updatedAt: string
}

export interface SocialMedia {
  instagram: string
  facebook: string
  website: string
  linkedIn: string
}

export interface IHospital {
  id: number
  name: string
  details: HospitalDetails
  doctors: { [key: number]: Doctor }
  equipments: { [key: number]: Equipment }
}

export interface IPortfolioImage {
  id: number
  path: string
  sortOrder: number
  portfolioId: number
  metadata: {
    crop?: { x: number; y: number; width: number; height: number }
    rotation?: number
    zoom?: number
    name?: string
  }
}

export interface IPortfolio {
  id: number
  name: string
  sortOrder: number
  userId: number
  portfolioImages: IPortfolioImage[]
}

export interface Portfolio {
  [key: number]: IPortfolio
}

export interface StagesTotal {
  treatment: { [key: number]: number }
  subTreatment: { [key: number]: number }
}

export interface SettingsState {
  stages: Stage[]
  hospital: IHospital | null
  portfolio: Portfolio | null
  stagesTotal: StagesTotal | null
}

export const initialState: SettingsState = {
  stages: [],
  hospital: null,
  portfolio: null,
  stagesTotal: null,
}
