import { Notification } from 'components/Notification'
import { useAppSelector } from 'store/hooks'
import { List } from './NotificationsBar.styled'
import { notificationsSelector } from 'store/notification/notification.selector'
import React from 'react'
import { useTransition, animated } from 'react-spring'

interface NotifyProps {
  type: 'auth' | 'header-visible' | 'header-not-visible'
}

const NotificationsBar: React.FC<NotifyProps> = ({ type }) => {
  const notifications = useAppSelector(notificationsSelector.selectNotifications)

  const transitions = useTransition(notifications, {
    keys: (item) => item.id,
    from: { opacity: 0, height: 0, transform: 'translateY(-20px)' },
    enter: () => async (next) =>
      await next({ opacity: 1, height: 'auto', transform: 'translateY(0px)' }),
    leave: { opacity: 0, height: 0, transform: 'translateY(-20px)' },
    config: { mass: 1, tension: 170, friction: 52 },
  })

  return (
    <List type={type}>
      {transitions((style, item) => (
        <animated.div key={item.id} style={style}>
          <Notification id={item.id} label={item.label} type={item.type} />
        </animated.div>
      ))}
    </List>
  )
}

export default NotificationsBar
