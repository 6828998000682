import styled from '@emotion/styled'

export const NotificationBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 480px;
  padding: 16px 12px 16px 24px;

  border-radius: 12px;
`
