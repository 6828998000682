import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { Hospital } from 'store/auth/auth.state'

export const deleteClinicLogo = createAsyncThunk<Hospital, number>(
  'settings/delete-clinic-logo',
  async (clinicId, thunkAPI) => {
    try {
      const response = await axios.delete(`/clinic/${clinicId}/image`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
