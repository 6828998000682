import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { Patient } from '../home.state'
import { RootState } from 'store'

export const getPatientsLazy = createAsyncThunk<Patient[]>(
  'home/get-patients-lazy',
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState
      const { page, perPage, searchPatientsValue } = state.home
      const querySearch = searchPatientsValue ? `&search=${searchPatientsValue}` : ''

      const response = await axios.get(`/patient?page=${page}&perPage=${perPage}${querySearch}`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
