import { Trans } from '@lingui/macro'
import { ErrorContainer, ErrorText } from './ScreenError.styled'

const ScreenError = () => {
  return (
    <ErrorContainer>
      <ErrorText>
        <Trans id='e6rhJ526QyXkwmj3CGQ6Tx-layout'>
          This application is supported only on screens 1024px wide or larger. Please open it on a
          desktop.
        </Trans>
      </ErrorText>
    </ErrorContainer>
  )
}

export default ScreenError
