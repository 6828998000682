import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { Patient } from '../home.state'
import { RootState } from 'store'

export const getPatientById = createAsyncThunk<Patient, number>(
  'home/get-patient-by-id',
  async (id, thunkAPI) => {
    try {
      const currentState = thunkAPI.getState() as RootState

      if (currentState.home.patients[id]) {
        return currentState.home.patients[id]
      }

      const response = await axios.get(`/patient/${id}`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
