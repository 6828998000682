import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Compressor from 'compressorjs'
import { Hospital } from 'store/auth/auth.state'

interface Credentials {
  clinicId: number
  image: File
  zoom: number
  crop: { x: number; y: number; width: number; height: number }
}

export const uploadClinicLogo = createAsyncThunk<Hospital, Credentials>(
  'settings/upload-clinic-logo',
  async ({ clinicId, image, zoom, crop }, thunkAPI) => {
    try {
      const compressedImage = await new Promise((resolve, reject) => {
        new Compressor(image, {
          quality: 0.8,
          success(result) {
            resolve(result)
          },
          error(err) {
            reject(err)
          },
        })
      })

      const formData = new FormData()
      formData.append('image', compressedImage as Blob)
      formData.append('zoom', zoom.toString())
      formData.append('crop', JSON.stringify(crop))

      // Отправка запроса
      const response = await axios.post(`/clinic/${clinicId}/image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
