import classnames from 'classnames'
import { Icon } from 'components/Icon'
import { Btn } from './CloseBtn.styled'
import React, { CSSProperties } from 'react'

interface Props {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
  style?: CSSProperties
}

const CloseBtn: React.FC<Props> = ({ onClick, className, style }) => {
  return (
    <Btn
      onClick={onClick}
      className={classnames(className, 'c-icon')}
      style={{
        ...style,
      }}
    >
      <Icon name='close' size={24} />
    </Btn>
  )
}

export default CloseBtn
