import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { IPortfolio } from 'store/settings/settings.state'

export const getCasesByDoctor = createAsyncThunk<IPortfolio[], string>(
  'patient/get-cases-by-doctor',
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`/presentation/doctor/cases/${id}`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
