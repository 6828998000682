import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { DiagnoseCategory } from '../treatment.types'

export const getDiagnosis = createAsyncThunk<DiagnoseCategory[]>(
  'treatment/get-diagnosis',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get('/diagnosis')

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
