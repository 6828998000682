import { Icon } from 'components/Icon'
import { LogoBox } from './Logo.styled'

const Logo = () => {
  return (
    <LogoBox>
      <Icon name='logo' width='137' height='44' />
    </LogoBox>
  )
}

export default Logo
