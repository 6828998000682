import { Colors } from 'constants/colors'

export enum NotificationType {
  Info = 'info',
  Success = 'success',
  Attention = 'attention',
  Alert = 'alert',
}

export const notificationColors = {
  [NotificationType.Info]: {
    backgroundColor: `${Colors.infoBgColor}`,
    color: `${Colors.blueAccent}`,
  },
  [NotificationType.Success]: {
    backgroundColor: `${Colors.successBgColor}`,
    color: `${Colors.successColor}`,
  },
  [NotificationType.Attention]: {
    backgroundColor: `${Colors.attentionBgColor}`,
    color: `${Colors.attentionColor}`,
  },
  [NotificationType.Alert]: {
    backgroundColor: `${Colors.errorBgColor}`,
    color: `${Colors.errorColor}`,
  },
}
