import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { IAppointment } from '../treatmentPlan.state'

export const createNewAppointment = createAsyncThunk<IAppointment, string>(
  'treatmentPlan/create-new-appointment',
  async (treatmentPlanId, thunkAPI) => {
    try {
      const response = await axios.post('appointment', { treatmentPlanId: Number(treatmentPlanId) })

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
