import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import { Tokens, User } from '../auth.state'
import { AppDispatch, RootState } from 'store'
import { setAuthToken } from './add-header-authorization'

axios.defaults.baseURL = `${process.env.REACT_APP_HOST_BACK}/api/`

interface Credentials {
  email: string
  password: string
}

interface SignInResponse {
  tokens: Tokens
  user: User
  response?: {
    status: number
  }
}

export type ThunkApiConfig = {
  state: RootState
  dispatch: AppDispatch
  rejectValue: AxiosError
}

export const signInOperation = createAsyncThunk<SignInResponse, Credentials, ThunkApiConfig>(
  'auth/sign-in',
  async (credentials, thunkAPI) => {
    try {
      const response = await axios.post('/auth/email/login', credentials)

      setAuthToken(response.data.tokens.accessToken)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
