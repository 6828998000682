import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { IVideo } from '../patient.types'
import { RootState } from 'store'

interface Request {
  toothId: number
  treatmentPlanId: number
}

export const getToothVideosByPatient = createAsyncThunk<IVideo[], Request>(
  'patient/get-tooth-videos-by-patient',
  async ({ toothId, treatmentPlanId }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState

      const config = {
        headers: {
          Authorization: state.patient.patient.personalId,
        },
      }

      const response = await axios.get(
        `/treatment-video/patient/${treatmentPlanId}?toothId=${toothId}`,
        config,
      )

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
