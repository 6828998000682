import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { Portfolio } from '../settings.state'

export const getAllPortfolio = createAsyncThunk<Portfolio>(
  'settings/get-portfolio',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get('/portfolio')

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
