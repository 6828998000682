import { Icon } from 'components/Icon'
import { NotificationBox } from './Notification.styled'
import { notificationColors } from './Notification.types'
import { useAppDispatch } from 'store/hooks'
import { notificationActions } from 'store/notification'
import { useEffect, useState } from 'react'
import { animated, useSpring } from 'react-spring'

interface Props {
  id: string
  label: string
  type: 'success' | 'info' | 'attention' | 'alert'
}

const Notification = ({ id, label, type }: Props) => {
  const dispatch = useAppDispatch()
  const style = notificationColors[type]
  const [isOpen, setIsOpen] = useState(false)

  const closeNotification = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => setIsOpen(true), 100)
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    if (!isOpen) {
      const timeoutId = setTimeout(() => {
        dispatch(notificationActions.remove({ id }))
      }, 250)

      return () => clearTimeout(timeoutId)
    }
  }, [isOpen, dispatch, id])

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(false)
    }, 3700)

    return () => clearTimeout(timer)
  }, [dispatch, id])

  const animation = useSpring({
    opacity: isOpen ? 1 : 0,
    config: { duration: 300 },
  })

  return (
    <animated.div style={animation}>
      <NotificationBox style={style}>
        <p>{label}</p>
        <Icon name='close' onClick={closeNotification} />
      </NotificationBox>
    </animated.div>
  )
}

export default Notification
