import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { ThunkApiConfig } from 'store/auth/operations/signIn.operation'
import { PatientRes } from './new-patient.operation'

export interface PatientDto {
  id: number
  firstName: string
  lastName: string
  personalId: string
}

export const changePatientInfo = createAsyncThunk<PatientRes, PatientDto, ThunkApiConfig>(
  'home/change-patient-info',
  async (patient, thunkAPI) => {
    try {
      const { id, ...patientInfo } = patient

      const response = await axios.put(`/patient/${id}`, patientInfo)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
