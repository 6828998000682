import axios from 'axios'
import { refreshTokenOperation } from 'store/auth/operations/refresh-tokens.operation'
import { store } from 'store'

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config

    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      error.config.url !== '/auth/refresh'
    ) {
      originalRequest._retry = true
      const tokens = store.getState().auth.tokens

      if (tokens?.refreshToken) {
        try {
          await store.dispatch(refreshTokenOperation(tokens.refreshToken))

          const updatedTokens = store.getState().auth.tokens

          originalRequest.headers['Authorization'] = `Bearer ${updatedTokens.accessToken}`
          return axios(originalRequest)
        } catch (refreshError) {
          return Promise.reject(refreshError)
        }
      }
    }

    return Promise.reject(error)
  },
)
