import styled from '@emotion/styled'
import { Toolbar } from '@mui/material'
import { Colors } from 'constants/colors'

export const HeaderToolbar = styled(Toolbar)`
  position: relative;
  display: flex;
  justify-content: space-between;

  margin-top: 20px;
  width: 100%;
  padding: 20px;

  border-radius: 100px;
  background-color: ${Colors.white};
`
