import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { Hospital, ToothType } from 'store/auth/auth.state'

interface Response {
  clinic: Hospital
  toothType: ToothType
}

export const getClinicInfoByDoctor = createAsyncThunk<Response, string>(
  'patient/get-clinic-info-by-doctor',
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`/presentation/doctor/clinic/${id}`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
