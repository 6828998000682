import { Router } from 'components/router'
import 'styles/globals.css'
import 'video-react/dist/video-react.css'
import 'react-image-crop/dist/ReactCrop.css'
import { Suspense, useEffect } from 'react'
import { useAppDispatch } from 'store/hooks'
import { refreshUserOperation } from 'store/auth/operations/refresh-user.operation'

const App = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(refreshUserOperation)
  }, [dispatch])

  return (
    <Suspense>
      <Router />
    </Suspense>
  )
}

export default App
