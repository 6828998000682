import { createAsyncThunk } from '@reduxjs/toolkit'
import { setAuthToken } from './add-header-authorization'
import axios from 'axios'
import { Tokens } from '../auth.state'

export const refreshTokenOperation = createAsyncThunk<Tokens, string>(
  'auth/refresh-token',
  async (refreshToken, thunkAPI) => {
    try {
      setAuthToken(refreshToken)

      const response = await axios.get('/auth/refresh')

      setAuthToken(response.data.accessToken)

      return response.data
    } catch (error) {


      return thunkAPI.rejectWithValue(error.message)
    }
  },
)
