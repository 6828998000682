import { TreatmentPlan } from 'store/home/home.state'
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

interface Request {
  id: number
  name?: string
  description?: string
}

export const changeTreatmentPlan = createAsyncThunk<TreatmentPlan, Request>(
  'treatmentPlan/change-treatment-plan',
  async ({ id, name, description }, thunkAPI) => {
    try {
      const response = await axios.patch(`/treatment-plan/${id}`, { name, description })

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
