import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { IPortfolio } from 'store/settings/settings.state'
import { IPatientTreatmentPlan, PatientAppointment } from '../patient.types'

interface Response {
  appointments: PatientAppointment[]
  cases: IPortfolio[]
  treatmentPlan: IPatientTreatmentPlan
}

export const getPresentationTreatmentPlanByDoctor = createAsyncThunk<Response, string>(
  'patient/get-presentation-tretmentPlan-by-doctor',
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`/presentation/doctor/treatment-plan/${id}`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
