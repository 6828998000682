import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { PriorityType, ToothDetail } from '../treatmentPlan.state'

interface Request {
  treatmentPlanId: number | string
  toothId: number
  description?: string
  priority?: PriorityType
}

export const changeToothInfo = createAsyncThunk<ToothDetail, Request>(
  'treatmentPlan/change-tooth-info',
  async ({ treatmentPlanId, toothId, description, priority }, thunkAPI) => {
    try {
      const response = await axios.patch(`/treatment-plan/${treatmentPlanId}/tooth/${toothId}`, {
        description,
        priority,
      })

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
