import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { Colors } from 'constants/colors'
import { fontStyle } from 'constants/fontStyle'

export const ErrorContainer = styled(Box)`
  display: none;
  @media (width < 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;

    z-index: 20000;
  }
`

export const ErrorText = styled.p`
  color: ${Colors.black};
  ${fontStyle.bodyTextmd400}
`
