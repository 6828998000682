import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { StagesTotal } from '../settings.state'

export const getStagesTotal = createAsyncThunk<StagesTotal>(
  'settings/get-stages-total',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get('/stages/total')

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
