import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { PatientAppointment } from '../patient.types'
import { RootState } from 'store'

export const getPatientAppointments = createAsyncThunk<PatientAppointment[], string>(
  'patient/get-patient-appointments',
  async (link, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState

      const config = {
        headers: {
          Authorization: state.patient.patient.personalId,
        },
      }

      const response = await axios.get(`/presentation/appointments/${link}`, config)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
