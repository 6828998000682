import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { Equipment } from 'store/auth/auth.state'

interface IResponse {
  message: string
  equipment: Equipment
}
interface ICredentials {
  clinicId: number
  equipmentId: number
}

export const deleteEquipmentById = createAsyncThunk<IResponse, ICredentials>(
  'settings/delete-equipment-by-id',
  async ({ clinicId, equipmentId }, thunkAPI) => {
    try {
      const response = await axios.delete(`/clinic/${clinicId}/equipment/${equipmentId}`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
