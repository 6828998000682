import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { IPortfolioImage } from '../settings.state'

interface Credentials {
  portfolioId: number
  imageId: number
  zoom: number
  crop: { x: number; y: number; width: number; height: number }
}

export const updateMetadataPortfolioImage = createAsyncThunk<IPortfolioImage, Credentials>(
  'settings/update-metadata-portfolio-image',
  async ({ portfolioId, imageId, zoom, crop }, thunkAPI) => {
    try {
      const response = await axios.put(`/portfolio/${portfolioId}/image/${imageId}`, {
        zoom,
        crop,
      })

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
