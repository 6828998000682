import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { TreatmentPlan } from '../home.state'

export const createTreatmentPlan = createAsyncThunk<TreatmentPlan, number>(
  'home/create-treatment-plan',
  async (patientId, thunkAPI) => {
    try {
      const response = await axios.post('/treatment-plan', { patientId })

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
