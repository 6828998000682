import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

interface Credentials {
  username: string
  clinicName: string
  email: string
  password: string
}

export const signUpOperation = createAsyncThunk(
  'auth/sign-up',
  async (credentials: Credentials, thunkAPI) => {
    try {
      const response = await axios.post('/auth/email/signup', credentials)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
