import { createAsyncThunk } from '@reduxjs/toolkit'
import { Stage } from '../settings.state'
import axios from 'axios'

interface Response {
  updatedStages: Stage[]
  errors: unknown
}

export const updateStages = createAsyncThunk<Response, Stage[]>(
  'settings/update-stages',
  async (credentials, thunkAPI) => {
    try {
      const stageDto = credentials.map(({ id, name, price, currency, duration, sortOrder }) => ({
        id,
        name,
        price,
        currency,
        duration,
        sortOrder,
      }))
      const response = await axios.put('/stages', stageDto)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
