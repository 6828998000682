import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { LoginInfo } from '../patient.types'

export const getTreatmentLogin = createAsyncThunk<LoginInfo, string>(
  'patient/get-presentation-login',
  async (link, thunkAPI) => {
    try {
      const response = await axios.get(`/presentation/${link}`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
