export interface Notification {
  id: string
  label: string
  type: 'success' | 'info' | 'attention' | 'alert'
}

export interface NotificationState {
  notifications: Notification[]
}

export const initialState: NotificationState = {
  notifications: [],
}
