import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { RootState } from 'store'
import { IPortfolio } from 'store/settings/settings.state'

export const getCases = createAsyncThunk<IPortfolio[], string>(
  'patient/get-cases',
  async (link, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState

      const config = {
        headers: {
          Authorization: state.patient.patient.personalId,
        },
      }

      const response = await axios.get(`/presentation/cases/${link}`, config)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
