import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { TreatmnentImage } from '../treatmentPlan.state'

interface Request {
  rotation: 90 | -90
  imageId: number
}

export const changeRotationTreatmentPlanImage = createAsyncThunk<TreatmnentImage, Request>(
  'treatmentPlan/change-rotation-image',
  async ({ imageId, rotation }, thunkAPI) => {
    try {
      const response = await axios.patch(`treatment-plan-images/${imageId}/rotation`, { rotation })

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
