import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { TreatmentVariant } from '../treatmentPlan.state'

interface Request {
  variantId: number
  toothId: number
}

interface Response {
  toothId: number
  variant: TreatmentVariant
}

export const deleteVariant = createAsyncThunk<Response, Request>(
  'treatmentPlan/delete-variant',
  async (credentails, thunkAPI) => {
    try {
      const response = await axios.delete(`/treatment-variant/${credentails.variantId}`)

      const payload = {
        toothId: credentails.toothId,
        variant: response.data.variant,
      }

      return payload
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
