import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { Equipment } from 'store/auth/auth.state'

interface Credentials {
  clinicId: number
  equipmentId: number
  name: string
  description: string
}

export const updateEquipment = createAsyncThunk<Equipment, Credentials>(
  'settings/update-equipment',
  async (credentials, thunkAPI) => {
    try {
      const { clinicId, equipmentId, name, description } = credentials

      const response = await axios.put(`/clinic/${clinicId}/equipment/${equipmentId}`, {
        name,
        description,
      })

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
