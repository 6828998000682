import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { RootState } from 'store'
import { Hospital, ToothType } from 'store/auth/auth.state'

interface Response {
  clinic: Hospital
  toothType: ToothType
}

export const getClinicInfo = createAsyncThunk<Response, string>(
  'patient/get-clinic-info',
  async (link, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState

      const config = {
        headers: {
          Authorization: state.patient.patient.personalId,
        },
      }

      const response = await axios.get(`/presentation/clinic/${link}`, config)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
