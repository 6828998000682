import { CurrencyType } from 'store/settings/settings.state'

export const currencyType = {
  [CurrencyType.USD]: '$',
  [CurrencyType.EURO]: '€',
  [CurrencyType.CZK]: 'Kč',
  [CurrencyType.UAH]: '₴',
}

export const currency = [
  // {
  //   label: 'USD',
  //   value: 'usd',
  // },
  // { label: 'EURO', value: 'euro' },
  { label: 'CZK', value: 'czk' },
  // { label: 'UAH', value: 'uah' },
]
