import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { TreatmnentImage } from '../treatmentPlan.state'
import Compressor from 'compressorjs'

interface Request {
  file: File
  treatmentPlanId: number | string
}

export const addTreatmentPlanImage = createAsyncThunk<TreatmnentImage, Request>(
  'treatmentPlan/add-image-to-treatmentPlan',
  async ({ file, treatmentPlanId }, thunkAPI) => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.8,
        success(compressedImage) {
          const formData = new FormData()
          formData.append('image', compressedImage)
          axios
            .post(`treatment-plan-images/${treatmentPlanId}`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((response) => resolve(response.data))
            .catch((error) => reject(thunkAPI.rejectWithValue(error)))
        },
        error(err) {
          reject(thunkAPI.rejectWithValue(err))
        },
      })
    })
  },
)

// export const addTreatmentPlanImage = createAsyncThunk<TreatmnentImage, Request>(
//   'treatmentPlan/add-image-to-treatmentPlan',
//   async ({ file, treatmentPlanId }, thunkAPI) => {
//     try {
//       const formData = new FormData()
//       formData.append('image', file)

//       const response = await axios.post(`treatment-plan-images/${treatmentPlanId}`, formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       })

//       return response.data
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error)
//     }
//   },
// )
