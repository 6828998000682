import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { Course } from 'store/auth/auth.state'

interface IResponse {
  message: string
  course: Course
}
interface ICredentials {
  clinicId: number
  doctorId: number
  courseId: number
}

export const deleteCourseById = createAsyncThunk<IResponse, ICredentials>(
  'settings/delete-course-by-id',
  async ({ clinicId, doctorId, courseId }, thunkAPI) => {
    try {
      const response = await axios.delete(
        `/clinic/${clinicId}/doctor/${doctorId}/course/${courseId}`,
      )

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
