import { CurrencyType, SocialMedia } from 'store/settings/settings.state'

export interface Course {
  id: number
  name: string
  hospitalDoctorId: number
  sortOrder: number
  year: string
}

export enum ToothType {
  USA = 'usa',
  EURO = 'euro',
}

export interface IFeedback {
  id: string
  name: string
  rating: string
  text: string
  sortOrder: number
}

export interface Doctor {
  id: number
  name: string
  specialization: string
  image: string
  metadata: {
    zoom: number
    crop: { x: number; y: number; width: number; height: number }
  }
  description: string
  feedback: IFeedback[]
  hospitalId: number
  courses: Course[]
}

interface Contacts {
  phone: string
  email: string
  location: string
}

export interface Equipment {
  id: number
  name: string
  description: string
  image: string
  metadata: {
    zoom: number
    crop: { x: number; y: number; width: number; height: number }
  }
  hospitalId: number
}

export interface HospitalDetails {
  logo: string
  description: string
  contacts: Contacts
  socialMedia: SocialMedia
  metadata: {
    zoom: number
    crop: { x: number; y: number; width: number; height: number }
  }
}

export interface Hospital {
  id: number
  name: string
  details: HospitalDetails
  doctors: Doctor[]
  equipments: Equipment[]
}

export interface User {
  id: number
  name: string
  email: string
  currency: CurrencyType
  toothType: ToothType
  verified: boolean
  createdAt: string
  hospital?: Hospital
}

export interface Tokens {
  accessToken: string | null
  refreshToken: string | null
}

export interface AuthState {
  isLoggedIn: boolean
  isLoading: boolean
  tokens: Tokens
  user: User | null
}

export const initialState: AuthState = {
  isLoggedIn: false,
  isLoading: false,
  tokens: {
    accessToken: null,
    refreshToken: null,
  },
  user: null,
}
