import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { ToothDetail } from '../treatmentPlan.state'

interface Request {
  toothId: number
  treatmentPlanId: number | string
}

export const getToothDescription = createAsyncThunk<ToothDetail, Request>(
  'treatmentPlan/get-tooth-description',
  async ({ toothId, treatmentPlanId }, thunkAPI) => {
    try {
      const response = await axios.get(
        `treatment-plan/${treatmentPlanId}/tooth-description/${toothId}`,
      )

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
