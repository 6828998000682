export const fontStyle = Object.freeze({
  description: `  
  font-family: Rubik;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0px;
  text-align: left;`,
  bodyText400: `
  font-family: Rubik;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.3rem;
  text-align: center;
  `,
  bodyTextsmx400: `
  font-family: Rubik;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0em;
  text-align: center;
  `,
  bodyTextmd600: `
  font-family: Rubik;
font-size: 1rem;
font-weight: 600;
line-height: 1.5rem;
letter-spacing: 0em;
text-align: left;
`,
  bodyTextmd700: `
font-family: Rubik;
font-size: 1rem;
font-weight: 700;
line-height: 1.5rem;
letter-spacing: 0px;
text-align: left;
`,
  labelDefault: `
  font-family: Rubik;
font-size: 1rem;
font-weight: 400;
line-height: 1.5rem;
letter-spacing: 0px;
text-align: left;
`,
  headlineH1: `
font-family: Rubik;
font-size: 2rem;
font-weight: 700;
line-height: 3rem;
letter-spacing: 0em;
text-align: left;
`,
  headlineH3: `
font-family: Rubik;
font-size: 1.3rem;
font-weight: 600;
line-height: 2rem;
letter-spacing: 0em;
text-align: left;
`,
  headlineH2: `
font-family: Rubik;
font-size: 1.5rem;
font-weight: 600;
line-height: 2rem;
letter-spacing: 0em;
text-align: left;
`,
  bodyTextmd400: `
font-family: Rubik;
font-size: 1rem;
font-weight: 400;
line-height: 1.5rem;
letter-spacing: 0em;
text-align: left;
`,
  bodyTextlg700: `
font-family: Rubik;
font-size: 1.1rem;
font-weight: 700;
line-height: 1.5rem;
letter-spacing: 0em;
text-align: left;
`,
  bodyTextlg400: `
font-family: Rubik;
font-size: 1.1rem;
font-weight: 400;
line-height: 1.5rem;
letter-spacing: 0em;
text-align: left;
`,
  chenla400: `
font-family: Chenla;
font-size: 1rem;
font-weight: 400;
line-height: 1.5rem;
letter-spacing: 0em;
text-align: left;`,
})
