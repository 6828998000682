import { createAsyncThunk } from '@reduxjs/toolkit'
import { Stage } from '../settings.state'
import axios from 'axios'

interface Credentials {
  treatmentId: number
  subTreatmentId: number | null
}

export const getStages = createAsyncThunk<Stage[], Credentials>(
  'settings/get-stages',
  async (credentials, thunkAPI) => {
    try {
      const sub = credentials.subTreatmentId ? `&subTreatmentId=${credentials.subTreatmentId}` : ''
      const response = await axios.get(`/stages?treatmentId=${credentials.treatmentId}${sub}`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
