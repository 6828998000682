import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { PatientAppointment } from '../patient.types'

export const getPatientAppointmentsByDoctor = createAsyncThunk<PatientAppointment[], string>(
  'patient/get-patient-appointments-by-doctor',
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`/presentation/doctor/appointments/${id}`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
