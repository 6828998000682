import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { LevelType, Priority } from '../treatmentPlan.state'

interface Request {
  teeth: number[]
  level: LevelType
  treatmentPlanId: number
}

export const createToothPriority = createAsyncThunk<Priority[], Request>(
  'treatmentPlan/create-tooth-priority',
  async (newPriority, thunkAPI) => {
    try {
      const response = await axios.post('/treatment-plan-priority', newPriority)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
