import { AppBar, Container } from '@mui/material'
import { Header } from 'modules/home/components/Header'
import { Outlet, useLocation } from 'react-router-dom'
import { authSelector } from 'store/auth'
import { useAppSelector } from 'store/hooks'
import { HeaderToolbar } from './MainLayout.styled'
import { Colors } from 'constants/colors'
import { NotificationsBar } from 'components/NotificationsBar'
import { useEffect, useRef, useState } from 'react'
import { ScreenError } from 'layouts/ScreenError'

const MainLayout = () => {
  const [isHeader, setIsHeader] = useState(true)
  const isLoggedIn = useAppSelector(authSelector.selectIsLoggedIn)
  const headerRef = useRef(null)
  const wrapperRef = useRef(null)
  const [isHeaderVisible, setIsHeaderVisible] = useState(true)
  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname.includes('patient-treatment-plan') || pathname.includes('patient-login')) {
      setIsHeader(false)
    }
  }, [pathname])

  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current) {
        const headerBottom = headerRef.current.getBoundingClientRect().bottom
        setIsHeaderVisible(headerBottom > 0)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  // if (pathname.includes('patient-treatment-plan')) return

  return (
    <div ref={wrapperRef} style={{ minHeight: '100vh' }}>
      {isHeader && (
        <>
          <ScreenError />
          <AppBar
            position='static'
            style={{ backgroundColor: Colors.darkWhite, boxShadow: 'none' }}
          >
            <Container
              style={{
                minWidth: '1024px',
                maxWidth: '1400px',
              }}
            >
              {isLoggedIn && (
                <HeaderToolbar ref={headerRef}>
                  <Header />
                  <NotificationsBar
                    type={isHeaderVisible ? 'header-visible' : 'header-not-visible'}
                  />
                </HeaderToolbar>
              )}
            </Container>
          </AppBar>
        </>
      )}
      <Outlet />
    </div>
  )
}

export default MainLayout
