import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './treatment.state'
import { getDiagnosis } from './operation/get-diagosis.operation'
import { getTreatments } from './operation/get-treatments.operation'

const treatmentSlice = createSlice({
  name: 'treatment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDiagnosis.fulfilled, (state, action) => {
        state.diagnosis = action.payload
      })
      .addCase(getTreatments.fulfilled, (state, action) => {
        state.treatments = action.payload
      })
  },
})

export const treatmentAction = treatmentSlice.actions
export const treatmentReducer = treatmentSlice.reducer
