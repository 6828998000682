import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { PatientAppointments } from '../home.state'

interface Response {
  treatmentAppointments: PatientAppointments[]
}

export const getTreatmentApointments = createAsyncThunk<Response, number>(
  'home/get-treatment-appointments',
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`/appointment/patient/${id}`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
