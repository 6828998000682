class LocalStorageService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static setItem(key: string, value: any): void {
    try {
      const serializedValue = JSON.stringify(value)
      localStorage.setItem(key, serializedValue)
    } catch (error) {
      console.error(`Error setting item in localStorage: ${error}`)
    }
  }

  static getItem<T>(key: string): T | null {
    try {
      const serializedValue = localStorage.getItem(key)
      if (serializedValue === null) {
        return null
      }
      return JSON.parse(serializedValue) as T
    } catch (error) {
      console.error(`Error getting item from localStorage: ${error}`)
      return null
    }
  }

  static async getItemAsync<T>(key: string): Promise<T | null> {
    return new Promise((resolve) => {
      resolve(LocalStorageService.getItem<T>(key))
    })
  }
}

export default LocalStorageService
