import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { Hospital } from 'store/auth/auth.state'

export const getClinicInfo = createAsyncThunk<Hospital, number>(
  'settings/get-clinic-info',
  async (clinicId, thunkAPI) => {
    try {
      const response = await axios.get(`/clinic/${clinicId}`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
