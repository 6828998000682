import { createAsyncThunk } from '@reduxjs/toolkit'
import { setAuthToken } from './add-header-authorization'
import axios from 'axios'
import { RootState } from 'store'
import { User } from '../auth.state'

export const refreshUserOperation = createAsyncThunk<User>(
  'auth/refresh-user',
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState

      setAuthToken(state.auth.tokens.accessToken)
      const response = await axios.get('/auth/user')

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
