import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { initialState } from './home.state'
import { getPatients } from './operations/get-patients.operation'
import { newPatient } from './operations/new-patient.operation'
import { changePatientInfo } from './operations/change-patient-info.operation'
import { deletePatient } from './operations/delete-patient.operation'
import { getPatientById } from './operations/get-patient-by-id.operation'
import { getSearchPatients } from './operations/get-search-patients.operation'
import { getPatientsLazy } from './operations/get-patients-lazy.operation'
import { getTreatmentPlans } from './operations/get-treatment-plans.operation'
import { createTreatmentPlan } from './operations/create-treatment-plan.operation'
import { deleteTreatmentPlan } from './operations/delete-treatment-plan.operation'
import { getTreatmentApointments } from './operations/get-patient-appointments.operation'

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    addPatientId: (state, action: PayloadAction<number | null>) => {
      state.patientId = action.payload
    },
    addSearchPatientValue: (state, action: PayloadAction<string>) => {
      state.searchPatientsValue = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPatients.pending, (state) => {
        state.patientsLoading = true
      })
      .addCase(getPatients.fulfilled, (state, action) => {
        action.payload.forEach((patient) => {
          state.patients.set(patient.id, patient)
        })
        state.page = state.page + 2
        state.perPage = 12
        state.patientsLoading = false
      })
      .addCase(newPatient.fulfilled, (state, action) => {
        if (!state.patients.has(action.payload.id)) {
          const updatedPatients = new Map([[action.payload.id, action.payload]])
          state.patients.forEach((patient, id) => {
            updatedPatients.set(id, patient)
          })
          state.patients = updatedPatients
        } else {
          state.patients.set(action.payload.id, action.payload)
        }
      })
      .addCase(changePatientInfo.fulfilled, (state, action) => {
        state.patients.set(action.payload.id, action.payload)
      })
      .addCase(deletePatient.fulfilled, (state, action) => {
        state.patients.delete(action.payload)
      })
      .addCase(getPatientById.fulfilled, (state, action) => {
        state.patients.set(action.payload.id, action.payload)
      })
      .addCase(getSearchPatients.fulfilled, (state, action) => {
        state.patients = new Map()

        action.payload.forEach((patient) => {
          state.patients.set(patient.id, patient)
        })
        state.page = state.page + 2
        state.perPage = 12
      })
      .addCase(getPatientsLazy.fulfilled, (state, action) => {
        action.payload.forEach((patient) => {
          state.patients.set(patient.id, patient)
        })
        state.page = state.page + 1
      })
      .addCase(getTreatmentPlans.pending, (state) => {
        state.patientTreatmentPlans = []
        state.patientAppointments = []
      })
      .addCase(getTreatmentPlans.fulfilled, (state, action) => {
        state.patientTreatmentPlans = action.payload.treatmentPlans
        // state.patientAppointments = action.payload.treatmentAppointments
      })
      .addCase(getTreatmentApointments.fulfilled, (state, action) => {
        state.patientAppointments = action.payload.treatmentAppointments
      })
      .addCase(createTreatmentPlan.fulfilled, (state, action) => {
        state.patientTreatmentPlans.push(action.payload)
        state.patientId = action.payload.patientId
      })
      .addCase(deleteTreatmentPlan.fulfilled, (state, action) => {
        state.patientTreatmentPlans = state.patientTreatmentPlans.filter(
          (plan) => plan.id !== action.payload,
        )
        state.patientAppointments = state.patientAppointments.filter(
          (app) => app.treatmentPlanId !== action.payload,
        )
      })
  },
})

export const homeActions = homeSlice.actions

export const homeReducer = homeSlice.reducer
