import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { TreatmnentImage } from '../treatmentPlan.state'
import { JawType } from 'modules/treatment/components/ImageOfPatientInfoPopup/ImageOfPatientInfoPopup.types'
import { Crop } from 'react-image-crop'

interface Request {
  imageId: number
  teeth?: { toothId: number; x: number; y: number }[]
  type?: JawType
  rotation?: number
  crop?: Crop
}

export const saveMetadataTreatmentPlanImage = createAsyncThunk<TreatmnentImage, Request>(
  'treatmentPlan/save-metadata-image',
  async ({ imageId, teeth, type, rotation, crop }, thunkAPI) => {
    try {
      const payload = {
        type,
        ...(teeth && { teeth }),
        ...(rotation !== undefined && { rotation }),
        ...(crop && { crop }),
      }

      const response = await axios.put(`treatment-plan-images/${imageId}`, payload)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
