import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const deletePatient = createAsyncThunk(
  'home/delete-patient',
  async (id: number, thunkAPI) => {
    try {
      const response = await axios.delete(`/patient/${id}`)

      if (response) return id
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
