import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

interface Response {
  message: string
  id: number
}

export const deleteTreatmentPlanImage = createAsyncThunk<Response, number>(
  'treatmentPlan/delete-image-to-treatmentPlan',
  async (imageId, thunkAPI) => {
    try {
      const response = await axios.delete(`treatment-plan-images/${imageId}`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
