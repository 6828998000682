import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { IVideo } from '../patient.types'

interface Request {
  toothId: number
  treatmentPlanId: number
}

export const getToothVideosByDoctor = createAsyncThunk<IVideo[], Request>(
  'patient/get-tooth-videos-by-doctor',
  async ({ toothId, treatmentPlanId }, thunkAPI) => {
    try {
      const response = await axios.get(
        `/treatment-video/doctor/${treatmentPlanId}?toothId=${toothId}`,
      )

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
