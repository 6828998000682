import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { TreatmentPlan } from '../treatmentPlan.state'

export enum OperationType {
  add = 'add',
  delete = 'delete',
}

interface Request {
  treatmentPlanId: number | string
  hospitalDoctorId?: number
  hospitalEquipmentId?: number
  userPortfolioId?: number
  type: OperationType
}

export const addReviewData = createAsyncThunk<TreatmentPlan, Request>(
  'treatmentPlan/add-review-data',
  async (
    { hospitalDoctorId, hospitalEquipmentId, userPortfolioId, type, treatmentPlanId },
    thunkAPI,
  ) => {
    try {
      const response = await axios.patch(`treatment-plan/${treatmentPlanId}/review`, {
        hospitalDoctorId,
        hospitalEquipmentId,
        userPortfolioId,
        type,
      })

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
