import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { TreatmentVariant } from '../treatmentPlan.state'

interface Request {
  teeth: number[]
  treatmentPlanId: number
  isMain?: boolean
  signal?: AbortSignal
}

export const createVariant = createAsyncThunk<TreatmentVariant, Request>(
  'treatmentPlan/create-variant',
  async (credentials, { rejectWithValue }) => {
    try {
      const { teeth, treatmentPlanId, isMain, signal } = credentials

      const response = await axios.post(
        'treatment-variant',
        { teeth, treatmentPlanId, isMain: isMain ? isMain : false },
        {
          signal: signal,
        },
      )

      const variant = response.data[0]
      if (!variant.patientTreatments) {
        variant.patientTreatments = []
      }

      return variant
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
