import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { Equipment } from 'store/auth/auth.state'

interface Credentials {
  clinicId: number
  equipmentId: number

  zoom: number
  crop: { x: number; y: number; width: number; height: number }
}

export const updateMetadataEquipment = createAsyncThunk<Equipment, Credentials>(
  'settings/update-metadata-equipment',
  async ({ clinicId, equipmentId, zoom, crop }, thunkAPI) => {
    try {
      const response = await axios.put(`/clinic/${clinicId}/equipment/${equipmentId}`, {
        zoom,
        crop,
      })

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
