import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { TreatmentPlan } from '../treatmentPlan.state'

export const getTreatmentPlanById = createAsyncThunk<TreatmentPlan, number>(
  'treatmentPlan/get-treatment-plan-id',
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`treatment-plan/${id}`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
