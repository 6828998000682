import { createSelector } from '@reduxjs/toolkit'
import { currencyType } from 'constants/currency'
import { toothNumbers } from 'constants/toothNumber'
import { RootState } from 'store'

class AuthSelector {
  selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn
  selectTokens = (state: RootState) => state.auth.tokens
  selectClinicId = (state: RootState) => state.auth.user.hospital.id
  selectClinic = (state: RootState) => state.auth.user.hospital
  selectAccountCurrency = (state: RootState) => state.auth.user.currency
  selectAccountCurrencyType = createSelector(
    (state: RootState) => state.auth.user.currency,
    (currency) => currencyType[currency],
  )
  selectUserName = (state: RootState) => state.auth.user.name
  selectToothType = (state: RootState) => state.auth.user.toothType
  selectTypedTooth = createSelector(this.selectToothType, (type) => {
    return (toothId) => {
      const typedNumbers = toothNumbers[type]
      if (!typedNumbers) {
        return toothId
      }

      return typedNumbers[toothId]
    }
  })
}

export const authSelector = new AuthSelector()
