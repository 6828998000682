import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { TreatmentCategory } from '../treatment.types'

export const getTreatments = createAsyncThunk<TreatmentCategory[]>(
  'treatment/get-treatments',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get('/treatment')

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
