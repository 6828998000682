import { RootState } from '../store'
import { createSelector } from '@reduxjs/toolkit'
class HomeSelector {
  getPatientsObject = (state: RootState) => state.home.patients

  getPatients = createSelector(
    (state: RootState) => state.home.patients,
    (patients) => {
      const values = patients.values()
      return [...values]
    },
  )

  selectPatientById = createSelector(
    (state: RootState) => state.home.patients,
    (patients) => (patientId: number) => {
      const patient = patients.get(patientId)

      if (!patient) return null

      return patient
    },
  )

  getPatientById = (state: RootState) => state.home.patients.get(state.home.patientId)
  getSearchPatientsValue = (state: RootState) => state.home.searchPatientsValue
  getPatientTreatmentPlans = (state: RootState) => state.home.patientTreatmentPlans
  selectPatientAppointment = (state: RootState) => state.home.patientAppointments
  selectIsLoadingPatients = (state: RootState) => state.home.patientsLoading
}

export const homeSelector = new HomeSelector()
