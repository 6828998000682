import { Logo } from '../Logo'
import { MenuBtn, ProfileBox, ProfileCard, ProfileImg } from './Header.styled'
import { Icon } from 'components/Icon'
import { Colors } from 'constants/colors'
import { useState } from 'react'
import { Popup } from '../Popup'
import { Menu } from 'components/Menu'
import { Trans } from '@lingui/macro'
import { useAppSelector } from 'store/hooks'
import { authSelector } from 'store/auth'
import { Box } from '@mui/material'
import useConditionalNavigate from 'hooks/useConditialNavigate'
import { LanguagesSelector } from 'components/LanguagesSelector'

const Header = () => {
  const clinic = useAppSelector(authSelector.selectClinic)
  const [isMenu, setIsMenu] = useState(false)
  const navigate = useConditionalNavigate()
  const toggleMenu = () => {
    setIsMenu(!isMenu)
  }

  const handleClick = (to: string) => {
    navigate(to)
  }

  return (
    <>
      <Box className='cursor-pointer' onClick={() => handleClick('/')}>
        <Logo />
      </Box>
      <ProfileBox>
        <LanguagesSelector />
        <ProfileCard onClick={() => handleClick('/settings')}>
          <div style={{ width: '34px', height: '34px', overflow: 'hidden', borderRadius: '50%' }}>
            {clinic?.details?.logo ? (
              <ProfileImg src={clinic.details.logo} alt='Avatar' />
            ) : (
              <Icon name='avatar' size={34} />
            )}
          </div>
          <p>{clinic.name || 'D. doctor'}</p>
        </ProfileCard>
        <MenuBtn onClick={toggleMenu}>
          <Icon name='menu' color={Colors.blueAccent} />
          <Trans id='c8FjNzq1B9F9TSBxYJUVNN-home'>Menu</Trans>
        </MenuBtn>
      </ProfileBox>
      {isMenu && (
        <Popup toggleModal={toggleMenu}>
          <Menu toggleMenu={toggleMenu} />
        </Popup>
      )}
    </>
  )
}

export default Header
