import { TransRenderProps } from '@lingui/react'
import { ReactNode } from 'react'

export interface ITranslatorProvider {
  children: ReactNode
}

export interface IDefaultComponent extends TransRenderProps {
  messages: Record<string, string>
}

export interface IlanguageObject {
  name: string
  value: ILanguage
  icon: string
}

interface ILanguages {
  en: IlanguageObject
  // uk: IlanguageObject
  // de: IlanguageObject
  cz: IlanguageObject
}

export const LANGUAGES: ILanguages = {
  en: { name: 'EN', value: 'en', icon: 'en' },
  // uk: { name: 'UA', value: 'uk', icon: 'ua' },
  // de: { name: 'DE', value: 'de', icon: 'de' },
  cz: { name: 'CZ', value: 'cz', icon: 'cz' },
}

export type ILanguage = keyof typeof LANGUAGES

export interface ITranslatorContext {
  language: ILanguage
  loadLocale: (language: ILanguage) => void
  languages: IlanguageObject[]
}
