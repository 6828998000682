import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { Doctor } from 'store/auth/auth.state'

export const createNewDoctor = createAsyncThunk<Doctor, number>(
  'settings/create-new-doctor',
  async (clinicId, thunkAPI) => {
    try {
      const response = await axios.post(`/clinic/${clinicId}/doctor`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
